import React from 'react'
import { Link } from 'react-router-dom'



function CardNominee({data, hideBtn}) {
  
  return (
    <div className='cardNominee flex flex-col shadow-lg hover:shadow-2xl m-3 overflow-hidden mb-5 w-11/12 md:w-68 lg:w-64'>
          <div className='cardImg h-52 w-full'>
              <img className='img-fit w-full block' loading='eager' style={{  objectFit: 'cover', objectPosition: 'top'}} src={data.imageUrl} alt="" />
          </div>

          <div className='flex flex-col items-center w-full text-center'>
             <p className='text-blue-950 font-semibold text-lg'>{data.name}</p>
             <p className=''>{data.code}</p>
          </div>
          <div className='text-center py-2 '>
              <p className='text-2xl font-bold text-gray-500'>{data.totalVotes}</p>
              <p className='text-xs'>Votes</p>
          </div>
          {
            !hideBtn && <Link to={`/nominee/${data.id}`} className='w-full h-full'>
            <div className='w-full text-white text-center font-bold cursor-pointer rounded-sm rounded-b-lg  p-3 bg-[#41003A] hover:bg-purple-800  active:bg-purple-900 focus:outline-none '>
              VOTE
            </div>
            </Link>
          }
          
    </div>

  )
}


export default CardNominee;